<template>
  <div class="participants2">
    <search-collapse title="" :senior="false" @handleSearch="getList">
      <template #search-left>
        <el-button
          type="primary"
          class="add"
          icon="el-icon-plus"
          @click="handleEdit"
          >新建</el-button
        >
      </template>
      <el-form class="el-form-custom" :inline="true" :model="queryCondition">
        <el-form-item label="">
          <el-input
            v-model="queryCondition.name"
            placeholder="请输入平台名称"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
    </search-collapse>

    <div class="row" ref="row" :style="`height:${rowHeight};`">
      <div class="col" v-if="showDataDemo">
        <div class="col-left">
          <el-table
            :data="tableData"
            highlight-current-row
            v-loading="tableLoading"
            @sort-change="changeSort"
            @row-click="rowClick"
            :row-class-name="tableRowClassName"
            ref="table"
            height="calc(100% - 64px)"
            class="custom_border"
          >
            <el-table-column
              prop="code"
              label="平台编号"
              min-width="140px"
              sortable="custom"
            />
            <!--            <el-table-column prop="name" label="平台名称" min-width="250px">-->
            <!--              <template slot-scope="scope">-->
            <!--                <TextOverflow :limit="15" :text="scope.row.name || '- -'" />-->
            <!--              </template>-->
            <!--            </el-table-column>-->

            <af-table-column prop="name" label="平台名称" min-width="250px">
              <template slot-scope="scope">
                <chacter-overflow :limit="30" :text="scope.row.name || '- -'" />
              </template>
            </af-table-column>

            <el-table-column
              prop="mode"
              label="持股方式"
              min-width="130px"
              sortable="custom"
            >
              <template slot-scope="scope">
                {{ scope.row.mode == "direct" ? "直接" : "间接" }}
              </template>
            </el-table-column>

            <el-table-column
              prop="status"
              label="状态"
              sortable="custom"
              min-width="100px"
            >
              <template slot-scope="scope">
                {{ scope.row.status == "1" ? "启用" : "禁用" }}
              </template>
            </el-table-column>

            <el-table-column prop="id" label="操作" min-width="130px">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  @click="handleEdit(scope.$index, scope.row)"
                >
                  修改
                </el-button>
                <el-divider direction="vertical"></el-divider>
                <el-button
                  type="text"
                  class="del_button"
                  @click="handleDel(scope.$index, scope.row)"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
            <el-table-column prop="ctime" label="创建时间" min-width="195px" />
            <af-table-column prop="creator" label="创建人" />
            <el-table-column prop="mtime" label="修改时间" min-width="195px" />
            <af-table-column prop="modifier" label="修改人" />
          </el-table>
          <!-- 分页 -->
          <pagination
            :total="total"
            :page-number.sync="queryCondition.page"
            :page-size.sync="queryCondition.limit"
            @pagination="getList"
          />
        </div>
        <div class="col-right" v-loading="loading">
          <el-tabs v-model="editableTabsValue" type="card">
            <el-tab-pane label="期权计划统计" name="0">
              <right-option :option="option_data" />
            </el-tab-pane>
            <el-tab-pane label="股票计划统计" name="1">
              <right-stock :option="stock_data" />
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <Empty v-if="!showDataDemo && requested" />
    </div>
    <Edit
      :data="edit_data"
      :visible="visible"
      @onClose="handleClose"
      @onSave="handleSave"
    />
  </div>
</template>
<script>
import SearchCollapse from "@/components/base/search-collapse";
import RightStock from "./components/RightStock.vue";
import RightOption from "./components/RightOption.vue";
import Edit from "./components/Edit.vue";
import Pagination from "@/components/base/pagination";
import tableLife from "@/mixins/tableLife.js";
import api from "@/api";
import Empty from "@/components/base/empty";
export default {
  mixins: [tableLife],
  components: {
    SearchCollapse,
    Pagination,
    RightStock,
    RightOption,
    Edit,
    Empty,
  },
  data() {
    return {
      editableTabsValue: "0",
      defaultSortWord: "code",
      total: 0,
      queryCondition: {
        name: "",
        page: 1,
        limit: 20,
      },
      visible: false,
      import_visible: false,
      slideData: {},
      option_data: {},
      stock_data: {},
      Empty: true,
      loading: true,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getApiTableData() {
      const data = await api.platform.platformList(this.queryCondition);
      this.loading = false;
      this.tableData = data.list;
      this.slideData = data.list[0];
      // console.log(data);
      this.rowClick(this.slideData);
      return data;
    },
    handleEdit(index, row) {
      console.log(row);
      this.edit_data = row ? row : {};
      this.visible = true;
    },
    handleImport() {
      this.import_visible = true;
    },
    handleDel(index, row) {
      this.$confirm("确定删除吗？", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(async () => {
          await api.platform.platformDel({ platform_id: row.platform_id });
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.getApiTableData();
        })
        .catch(() => {});
    },
    // 保存
    handleSave() {
      this.handleClose();
      this.getList();
    },
    // 关闭编辑模态窗
    handleClose() {
      this.visible = false;
      this.import_visible = false;
    },
    //点击
    async rowClick(row) {
      // this.option_data={}
      // this.stock_data={}
      this.loading = true;
      try {
        const data = await api.platform.platformDataV(row.platform_id);
        this.option_data = data.option_stat;
        this.stock_data = data.stock_stat;
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.participants2 {
  ///deep/ .row .col .col-right .el-tabs.el-tabs--card.el-tabs--top .el-tabs__content .el-tab-pane{
  //  height: 0!important;
  //}

  height: 100%;
  .el-form-custom {
    text-align: right;
    .el-form-item {
      margin-bottom: 0;
    }
  }
  .add {
  }
  .export {
    padding-left: 12px;
    .img_icon {
      width: 20px;
      margin-right: 8px;
    }
  }

  .col-right {
    overflow: scroll;
  }
}
</style>
