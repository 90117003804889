<template>
  <el-dialog
    class="custom-dialog"
    :title="`${data && data.platform_id ? '修改持股平台' : '新建持股平台'}`"
    :visible.sync="visible"
    :before-close="handleBeforeClose"
    :close-on-click-modal="false"
  >
    <div>
      <el-form
        ref="form"
        :model="info"
        v-loading="getDetailLoading"
        :rules="formRules"
        hide-required-asterisk
        label-width="75px"
      >
        <el-form-item label="平台名称" prop="name">
          <el-input
            v-model="info.name"
            placeholder="请输入持股平台名称"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="持股方式">
          <el-select v-model="info.mode" placeholder="请选择持股方式">
            <el-option
              v-for="item in modeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="info.status" placeholder="请选择状态">
            <el-option
              v-for="item in statusList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取消</el-button>
      <el-button :loading="submitLoading" type="primary" @click="handleSubmit"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import api from "@/api";
import dialogLife from "@/mixins/dialogLife";
import { deepClone } from "@/utils/core";
const defaultInfo = {
  name: "",
  mode: "direct",
  status: "1",
};

export default {
  mixins: [dialogLife],
  components: {},
  data() {
    return {
      infoBackup: deepClone(defaultInfo),
      info: deepClone(defaultInfo),
      formRules: {
        name: [
          { required: true, message: "请输入持股平台名称", trigger: "change" },
        ],
      },
      modeList: [
        {
          id: "direct",
          name: "直接",
        },
        {
          id: "indirect",
          name: "间接",
        },
      ],
      statusList: [
        {
          id: "1",
          name: "启用",
        },
        {
          id: "2",
          name: "禁用",
        },
      ],
    };
  },
  methods: {
    async getDetail() {
      console.log(this.data);
      if (!this.data || !this.data.platform_id) return;
      this.getDetailLoading = true;
      let data = deepClone(this.data);
      this.info = data;
      this.infoBackup = data;
      this.getDetailLoading = false;
    },
    async submitMethod() {
      const { name, mode, status, platform_id } = this.info;
      if (platform_id)
        await api.platform.platformEdit({
          name,
          mode,
          status,
          platform_id,
        });
      else
        await api.platform.platformAdd({
          name,
          mode,
          status,
        });
    },
    handleClose() {
      this.$refs.form.resetFields();
      this.info = defaultInfo;
      this.infoBackup = defaultInfo;
      this.$emit("onClose");
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-dialog {
  .el-dialog__footer {
    // padding: 0;
  }
}
</style>
