<template>
  <div class="right-sta" v-loading="loading">
    <div class="flex-h flex-vc flex-jsb">
      <div class="left">
        <ChartNum
          name="计划数量"
          :num="option_stat.number"
          backgroundImage=""
          shadowColor=""
        />
        <ChartNum
          v-for="item in optionStatRe"
          :key="item.color"
          :name="item.name"
          :num="item.percent"
          :tipText="item.tipText || ''"
          :backgroundImage="item.lineColor"
          :shadowColor="item.shadowColor"
        />
      </div>
      <div class="chart-box">
        <!-- <RingLine :data="optionStat" ele="option" /> -->
        <Ring
          :name="'已授予'"
          :subtext="
            parseFloat(option_stat.total_qty) == 0
              ? '0%'
              : (
                  (
                    parseFloat(option_stat.award_qty) /
                    parseFloat(option_stat.total_qty)
                  ).toFixed(2) * 100
                ).toFixed(2) + '%'
          "
          :value="
            parseFloat(option_stat.total_qty) == 0
              ? 0
              : (
                  (parseFloat(option_stat.award_qty) /
                    parseFloat(option_stat.total_qty)) *
                  100
                ).toFixed(2)
          "
          :color1="'#866BFF'"
          :color2="'#6e51ed'"
          :color3="'#5A39EB'"
          :id="'sychart4'"
          :subColor="'#ECF0F3'"
          :shadowcolor="'rgba(134,107,255,0.5)'"
          :height="172"
        ></Ring>
      </div>
    </div>
    <div class="flex-h flex-vc flex-jsb mt-64">
      <div class="left">
        <ChartNum
          name="授予总数(股)"
          :num="option_stat.award_qty"
          backgroundImage=""
          shadowColor=""
        />
        <ChartNum
          name="归属总数(股)"
          :num="option_stat.actual_qty"
          tipText=""
          backgroundImage="linear-gradient(90deg, #FF2699 0%, #FF77BF 100%);"
          shadowColor="rgba(255,160,97,0.50)"
        />
      </div>
      <div class="chart-box">
        <Canvasdash
          :id="'chartstop9834'"
          :value="
            parseFloat(option.award_qty) == 0
              ? 0
              : parseFloat(option.actual_qty) / parseFloat(option.award_qty)
          "
          :height="74"
          :color1="'rgba(255,38,153,1)'"
          :color2="'rgba(255,119,191,1)'"
          :shadowcolor="'rgba(254,118,191,0.5)'"
          :shadowcolor2="'rgba(255,119,191,0.3)'"
          :name="'已归属'"
        ></Canvasdash>
      </div>
    </div>
  </div>
</template>
<script>
import ChartNum from "@/components/business/chart-num";
// import RingLine from "@/components/charts/RingLine";
import Ring from "@/components/charts/ring";
import { deepClone } from "@/utils/core";
import Canvasdash from "@/components/charts/canvasdash";
export default {
  components: {
    ChartNum,
    Ring,
    // RingLine,
    Canvasdash,
  },
  props: {
    option: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {
        number: 0,
        total_qty: 0,
        award_qty: 0,
        surplus_qty: 0,
        mature_qty: 0,
        actual_qty: 0,
      },
    },
  },
  watch: {
    option(val, oval) {
      if (val !== oval) this.getDetail();
    },
  },
  computed: {
    optionStatRe() {
      const arr = JSON.parse(JSON.stringify(this.optionStat));
      return arr.reverse();
    },
  },
  data() {
    return {
      option_stat: {},
      optionStat: [],
      loading: false,
    };
  },
  mounted() {},
  methods: {
    getDetail() {
      this.loading = true;
      const res = deepClone(this.option);
      this.option_stat = res;
      this.optionStat = [
        {
          name: "剩余总数(股)",
          percent: Number(res.surplus_qty),
          color: "l(0) 0:#57EB99 1:#0DCD63",
          lineColor: "linear-gradient(rgb(236, 240, 243), rgb(236, 240, 243));",
          shadowColor: "rgba(0,0,0,0)",
        },
        {
          name: "授予总数(股)",
          percent: Number(res.award_qty),
          color: "l(0) 0:#866BFF 1:#5A39EB",
          lineColor: "linear-gradient(270deg, #866BFF 0%, #5A39EB 100%);",
          shadowColor: "rgba(255,160,97,0.5)",
          tipText:
            "<div>统计中的“已授予数”与授予记录中的“授予股<br />数”不是简单的汇总关系。</div><div>考虑了“已终止”状态的授予记录中“已回收数”<br />对统计中“已授予数”的影响。</div>",
        },
        {
          name: "总股数(股)",
          percent: Number(res.total_qty),
          // color: "l(0) 0:#FF1960 1:#FF6B99",
          // lineColor: "linear-gradient(90deg, #FF1960 0%, #FF6B99 100%);",
          // shadowColor: "rgba(255,107,153,0.5)",
        },
      ];
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.right-sta {
  height: 100%;
  overflow-y: auto;
  .chart_title {
    margin-bottom: 17px;
  }
  /deep/ .chart-num {
    margin-bottom: 16px;
  }
}
</style>
